
import _ from "lodash"
import ep from "~/api/endpoint"
import { TAG_COMPONENT_BY_TYPES, TAG_TYPES } from "../../constants/tag"

export default {
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "tag_default", // "tag_contact" | "tag_chat" | "tag_case" | "tag_broadcast" | "tag_default"
    },
    popover_btn_type: {
      type: String,
      default: "button", // "button" | "icon" | "icon-button"
    },
    popover_btn_text: {
      type: String,
      default: "เพิ่มแท็ก",
    },
    show_tag_list: {
      type: Boolean,
      default: true,
    },
    show_referral_list: {
      type: Boolean,
      default: false,
    },
    contact_id: {
      type: String,
      default: "",
    },
    case_id: {
      type: String,
      default: "",
    },
    is_limit_exceeded: {
      type: Boolean,
      default: false,
    },
    data_id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tag_component_by_types: TAG_COMPONENT_BY_TYPES,
      tag_types: TAG_TYPES,
    }
  },
  computed: {
    component_type() {
      return (
        this.tag_component_by_types[this.type] ??
        this.tag_component_by_types.tag_deafult
      )
    },
    tag_permission() {
      switch (this.type) {
        case this.tag_types.tag_contact:
          return this.$permission.validate("contact.contact-tag.add")
        case this.tag_types.tag_chat:
          return this.$permission.validate("contact.chat-tag.add")
        case this.tag_types.tag_case:
          return this.$permission.validate("case.tag.add")
        case this.tag_types.tag_broadcast:
          return this.$permission.validate("broadcast.create")
        default:
          return true
      }
    },
    untag_permission() {
      switch (this.type) {
        case this.tag_types.tag_contact:
          return this.$permission.validate("contact.contact-tag.remove")
        case this.tag_types.tag_chat:
          return this.$permission.validate("contact.chat-tag.remove")
        case this.tag_types.tag_case:
          return this.$permission.validate("case.tag.remove")
        case this.tag_types.tag_broadcast:
          return this.$permission.validate("broadcast.create")
        default:
          return true
      }
    },
    create_tag_permission() {
      switch (this.type) {
        case this.tag_types.tag_contact:
          return this.$permission.validate("keyword.contact-tag.create")
        case this.tag_types.tag_chat:
          return this.$permission.validate("keyword.chat-tag.create")
        case this.tag_types.tag_case:
          return this.$permission.validate("keyword.case-tag.create")
        case this.tag_types.tag_broadcast:
          return this.$permission.validate("broadcast.create")
        default:
          return true
      }
    },
    edit_tag_permission() {
      switch (this.type) {
        case this.tag_types.tag_contact:
          return this.$permission.validate("keyword.contact-tag.update")
        case this.tag_types.tag_chat:
          return this.$permission.validate("keyword.chat-tag.update")
        case this.tag_types.tag_case:
          return this.$permission.validate("keyword.case-tag.update")
        case this.tag_types.tag_broadcast:
          return this.$permission.validate("broadcast.create")
        default:
          return true
      }
    },
    remove_tag_permission() {
      switch (this.type) {
        case this.tag_types.tag_contact:
          return this.$permission.validate("keyword.contact-tag.remove")
        case this.tag_types.tag_chat:
          return this.$permission.validate("keyword.chat-tag.remove")
        case this.tag_types.tag_case:
          return this.$permission.validate("keyword.case-tag.remove")
        case this.tag_types.tag_broadcast:
          return this.$permission.validate("broadcast.create")
        default:
          return true
      }
    },
    show_popover_button() {
      return this.tag_permission
    },
    show_tag_button() {
      return this.tag_permission
    },
    show_untag_button() {
      return !this.show_referral_list && this.untag_permission
    },
    show_create_button() {
      return this.create_tag_permission
    },
    show_edit_button() {
      return this.edit_tag_permission
    },
    show_remove_button() {
      return this.remove_tag_permission
    },
  },
  methods: {
    async onTag(tag_data) {
      if (this.contact_id || this.case_id) {
        if (this.type === this.tag_types.tag_contact) {
          await this.onSubmitTagTagContact(tag_data)
        } else if (this.type === this.tag_types.tag_chat) {
          await this.onSubmitTagTagChat(tag_data)
        } else if (this.type === this.tag_types.tag_case) {
          await this.onSubmitTagTagCase(tag_data)
        }
      }

      this.$emit("onTag", tag_data)
    },
    async onUntag(tag_data) {
      if (this.contact_id || this.case_id) {
        if (this.type === this.tag_types.tag_contact) {
          await this.onSubmitUntagTagContact(tag_data)
        } else if (this.type === this.tag_types.tag_chat) {
          await this.onSubmitUntagTagChat(tag_data)
        } else if (this.type === this.tag_types.tag_case) {
          await this.onSubmitUntagTagCase(tag_data)
        }
      }

      this.$emit("onUntag", tag_data)
    },
    async onCreateTag(tag_data) {
      this.$emit("onCreateTag", tag_data)
    },
    async onEditTag(tag_data) {
      this.$emit("onEditTag", tag_data)
    },
    async onRemoveTag(tag_data) {
      this.$emit("onRemoveTag", tag_data)
    },
    async onSubmitTagTagContact(tag_data) {
      try {
        await this.$ohoMemberApi.$post(
          `${ep.contact}/${this.contact_id}/tags`,
          {
            tags: [tag_data._id],
          }
        )
      } catch (error) {
        this.$logger.error(_.get(error, "response.data", "เกิดข้อผิดพลาด"))
      }
    },
    async onSubmitTagTagChat(tag_data) {
      try {
        await this.$ohoMemberApi.$post(
          `${ep.contact}/${this.contact_id}/labels`,
          {
            labels: [tag_data._id],
          }
        )
      } catch (error) {
        this.$logger.error(_.get(error, "response.data", "เกิดข้อผิดพลาด"))
      }
    },
    async onSubmitTagTagCase(tag_data) {
      try {
        await this.$ohoMemberApi.$patch(`${ep.case}/${this.case_id}`, {
          add_tags_migrate_objectid: tag_data._id,
        })
      } catch (error) {
        this.$logger.error(_.get(error, "response.data", "เกิดข้อผิดพลาด"))
      }
    },
    async onSubmitUntagTagContact(tag_data) {
      try {
        await this.$ohoMemberApi.$post(
          `${ep.contact}/${this.contact_id}/tags`,
          {
            untags: [tag_data._id],
          }
        )
      } catch (error) {
        this.$logger.error(_.get(error, "response.data", "เกิดข้อผิดพลาด"))
      }
    },
    async onSubmitUntagTagChat(tag_data) {
      try {
        await this.$ohoMemberApi.$post(
          `${ep.contact}/${this.contact_id}/labels`,
          {
            unlabels: [tag_data._id],
          }
        )
      } catch (error) {
        this.$logger.error(_.get(error, "response.data", "เกิดข้อผิดพลาด"))
      }
    },
    async onSubmitUntagTagCase(tag_data) {
      try {
        await this.$ohoMemberApi.$patch(`${ep.case}/${this.case_id}`, {
          remove_tags_migrate_objectid: tag_data._id,
        })
      } catch (error) {
        this.$logger.error(_.get(error, "response.data", "เกิดข้อผิดพลาด"))
      }
    },
  },
}
