export const AddBusinessCard = () => import('../../components/AddBusinessCard.vue' /* webpackChunkName: "components/add-business-card" */).then(c => wrapFunctional(c.default || c))
export const AlertBar = () => import('../../components/AlertBar.vue' /* webpackChunkName: "components/alert-bar" */).then(c => wrapFunctional(c.default || c))
export const AlertMessage = () => import('../../components/AlertMessage.vue' /* webpackChunkName: "components/alert-message" */).then(c => wrapFunctional(c.default || c))
export const Announcement = () => import('../../components/Announcement.vue' /* webpackChunkName: "components/announcement" */).then(c => wrapFunctional(c.default || c))
export const AssigneeList = () => import('../../components/AssigneeList.vue' /* webpackChunkName: "components/assignee-list" */).then(c => wrapFunctional(c.default || c))
export const AutocompleteSuggestions = () => import('../../components/AutocompleteSuggestions.vue' /* webpackChunkName: "components/autocomplete-suggestions" */).then(c => wrapFunctional(c.default || c))
export const BubbleCorner = () => import('../../components/BubbleCorner.vue' /* webpackChunkName: "components/bubble-corner" */).then(c => wrapFunctional(c.default || c))
export const BusinessImage = () => import('../../components/BusinessImage.vue' /* webpackChunkName: "components/business-image" */).then(c => wrapFunctional(c.default || c))
export const ChangeRoleAlert = () => import('../../components/ChangeRoleAlert.vue' /* webpackChunkName: "components/change-role-alert" */).then(c => wrapFunctional(c.default || c))
export const ChannelsBadge = () => import('../../components/ChannelsBadge.vue' /* webpackChunkName: "components/channels-badge" */).then(c => wrapFunctional(c.default || c))
export const ContactInfo = () => import('../../components/ContactInfo.vue' /* webpackChunkName: "components/contact-info" */).then(c => wrapFunctional(c.default || c))
export const ContactName = () => import('../../components/ContactName.vue' /* webpackChunkName: "components/contact-name" */).then(c => wrapFunctional(c.default || c))
export const ContactProfileEdit = () => import('../../components/ContactProfileEdit.vue' /* webpackChunkName: "components/contact-profile-edit" */).then(c => wrapFunctional(c.default || c))
export const CustomIconButton = () => import('../../components/CustomIconButton.vue' /* webpackChunkName: "components/custom-icon-button" */).then(c => wrapFunctional(c.default || c))
export const FeedbackSurvey = () => import('../../components/FeedbackSurvey.vue' /* webpackChunkName: "components/feedback-survey" */).then(c => wrapFunctional(c.default || c))
export const GlobalHeader = () => import('../../components/GlobalHeader.vue' /* webpackChunkName: "components/global-header" */).then(c => wrapFunctional(c.default || c))
export const HtmlTextOverflow = () => import('../../components/HtmlTextOverflow.vue' /* webpackChunkName: "components/html-text-overflow" */).then(c => wrapFunctional(c.default || c))
export const InfoBar = () => import('../../components/InfoBar.vue' /* webpackChunkName: "components/info-bar" */).then(c => wrapFunctional(c.default || c))
export const IntroduceApplicationDialog = () => import('../../components/IntroduceApplicationDialog.vue' /* webpackChunkName: "components/introduce-application-dialog" */).then(c => wrapFunctional(c.default || c))
export const KeywordSuggess = () => import('../../components/KeywordSuggess.vue' /* webpackChunkName: "components/keyword-suggess" */).then(c => wrapFunctional(c.default || c))
export const LoginCard = () => import('../../components/LoginCard.vue' /* webpackChunkName: "components/login-card" */).then(c => wrapFunctional(c.default || c))
export const ManualLink = () => import('../../components/ManualLink.vue' /* webpackChunkName: "components/manual-link" */).then(c => wrapFunctional(c.default || c))
export const MaxPanel = () => import('../../components/MaxPanel.vue' /* webpackChunkName: "components/max-panel" */).then(c => wrapFunctional(c.default || c))
export const MobileNav = () => import('../../components/MobileNav.vue' /* webpackChunkName: "components/mobile-nav" */).then(c => wrapFunctional(c.default || c))
export const ModalExpandFile = () => import('../../components/ModalExpandFile.vue' /* webpackChunkName: "components/modal-expand-file" */).then(c => wrapFunctional(c.default || c))
export const NavBar = () => import('../../components/NavBar.vue' /* webpackChunkName: "components/nav-bar" */).then(c => wrapFunctional(c.default || c))
export const NavigationMenu = () => import('../../components/NavigationMenu.vue' /* webpackChunkName: "components/navigation-menu" */).then(c => wrapFunctional(c.default || c))
export const Noter = () => import('../../components/Noter.vue' /* webpackChunkName: "components/noter" */).then(c => wrapFunctional(c.default || c))
export const Onboarding = () => import('../../components/Onboarding.vue' /* webpackChunkName: "components/onboarding" */).then(c => wrapFunctional(c.default || c))
export const ProfileImage = () => import('../../components/ProfileImage.vue' /* webpackChunkName: "components/profile-image" */).then(c => wrapFunctional(c.default || c))
export const QuotaExceedDialog = () => import('../../components/QuotaExceedDialog.vue' /* webpackChunkName: "components/quota-exceed-dialog" */).then(c => wrapFunctional(c.default || c))
export const ReactiveContainer = () => import('../../components/ReactiveContainer.vue' /* webpackChunkName: "components/reactive-container" */).then(c => wrapFunctional(c.default || c))
export const RegisterCard = () => import('../../components/RegisterCard.vue' /* webpackChunkName: "components/register-card" */).then(c => wrapFunctional(c.default || c))
export const SearchSlowDialog = () => import('../../components/SearchSlowDialog.vue' /* webpackChunkName: "components/search-slow-dialog" */).then(c => wrapFunctional(c.default || c))
export const SelectChannels = () => import('../../components/SelectChannels.vue' /* webpackChunkName: "components/select-channels" */).then(c => wrapFunctional(c.default || c))
export const Sidenav = () => import('../../components/Sidenav.vue' /* webpackChunkName: "components/sidenav" */).then(c => wrapFunctional(c.default || c))
export const SubMenu = () => import('../../components/SubMenu.vue' /* webpackChunkName: "components/sub-menu" */).then(c => wrapFunctional(c.default || c))
export const SubMenuSide = () => import('../../components/SubMenuSide.vue' /* webpackChunkName: "components/sub-menu-side" */).then(c => wrapFunctional(c.default || c))
export const SwitchBusiness = () => import('../../components/SwitchBusiness.vue' /* webpackChunkName: "components/switch-business" */).then(c => wrapFunctional(c.default || c))
export const TextOverflow = () => import('../../components/TextOverflow.vue' /* webpackChunkName: "components/text-overflow" */).then(c => wrapFunctional(c.default || c))
export const TextOverflowWithLimit = () => import('../../components/TextOverflowWithLimit.vue' /* webpackChunkName: "components/text-overflow-with-limit" */).then(c => wrapFunctional(c.default || c))
export const TimeCounter = () => import('../../components/TimeCounter.vue' /* webpackChunkName: "components/time-counter" */).then(c => wrapFunctional(c.default || c))
export const TimeDiff = () => import('../../components/TimeDiff.vue' /* webpackChunkName: "components/time-diff" */).then(c => wrapFunctional(c.default || c))
export const TimeFormated = () => import('../../components/TimeFormated.vue' /* webpackChunkName: "components/time-formated" */).then(c => wrapFunctional(c.default || c))
export const TimerCountdown = () => import('../../components/TimerCountdown.vue' /* webpackChunkName: "components/timer-countdown" */).then(c => wrapFunctional(c.default || c))
export const AdvertisementARPCustomPayload = () => import('../../components/Advertisement/ARPCustomPayload.vue' /* webpackChunkName: "components/advertisement-a-r-p-custom-payload" */).then(c => wrapFunctional(c.default || c))
export const AdvertisementTikTokIntegration = () => import('../../components/Advertisement/TikTokIntegration.vue' /* webpackChunkName: "components/advertisement-tik-tok-integration" */).then(c => wrapFunctional(c.default || c))
export const Advertisement = () => import('../../components/Advertisement/index.vue' /* webpackChunkName: "components/advertisement" */).then(c => wrapFunctional(c.default || c))
export const AutoReplyAlertBar = () => import('../../components/AutoReply/AlertBar.vue' /* webpackChunkName: "components/auto-reply-alert-bar" */).then(c => wrapFunctional(c.default || c))
export const AutoReplyCarouselBubble = () => import('../../components/AutoReply/CarouselBubble.vue' /* webpackChunkName: "components/auto-reply-carousel-bubble" */).then(c => wrapFunctional(c.default || c))
export const AutoReplyCustomPayloadModal = () => import('../../components/AutoReply/CustomPayloadModal.vue' /* webpackChunkName: "components/auto-reply-custom-payload-modal" */).then(c => wrapFunctional(c.default || c))
export const AutoReplyEditorCondition = () => import('../../components/AutoReply/EditorCondition.vue' /* webpackChunkName: "components/auto-reply-editor-condition" */).then(c => wrapFunctional(c.default || c))
export const AutoReplyEditorExternalAPI = () => import('../../components/AutoReply/EditorExternalAPI.vue' /* webpackChunkName: "components/auto-reply-editor-external-a-p-i" */).then(c => wrapFunctional(c.default || c))
export const AutoReplyEditorInputPattern = () => import('../../components/AutoReply/EditorInputPattern.vue' /* webpackChunkName: "components/auto-reply-editor-input-pattern" */).then(c => wrapFunctional(c.default || c))
export const AutoReplyEditorTriggersOut = () => import('../../components/AutoReply/EditorTriggersOut.vue' /* webpackChunkName: "components/auto-reply-editor-triggers-out" */).then(c => wrapFunctional(c.default || c))
export const AutoReplyEditorUserInput = () => import('../../components/AutoReply/EditorUserInput.vue' /* webpackChunkName: "components/auto-reply-editor-user-input" */).then(c => wrapFunctional(c.default || c))
export const AutoReplyExternalAPIForm = () => import('../../components/AutoReply/ExternalAPIForm.vue' /* webpackChunkName: "components/auto-reply-external-a-p-i-form" */).then(c => wrapFunctional(c.default || c))
export const AutoReplyFileBubble = () => import('../../components/AutoReply/FileBubble.vue' /* webpackChunkName: "components/auto-reply-file-bubble" */).then(c => wrapFunctional(c.default || c))
export const AutoReplyImageBubble = () => import('../../components/AutoReply/ImageBubble.vue' /* webpackChunkName: "components/auto-reply-image-bubble" */).then(c => wrapFunctional(c.default || c))
export const AutoReplyLineCustomPayloadBubble = () => import('../../components/AutoReply/LineCustomPayloadBubble.vue' /* webpackChunkName: "components/auto-reply-line-custom-payload-bubble" */).then(c => wrapFunctional(c.default || c))
export const AutoReplyListView = () => import('../../components/AutoReply/ListView.vue' /* webpackChunkName: "components/auto-reply-list-view" */).then(c => wrapFunctional(c.default || c))
export const AutoReplyMessageBubble = () => import('../../components/AutoReply/MessageBubble.vue' /* webpackChunkName: "components/auto-reply-message-bubble" */).then(c => wrapFunctional(c.default || c))
export const AutoReplyQuickReplyBubble = () => import('../../components/AutoReply/QuickReplyBubble.vue' /* webpackChunkName: "components/auto-reply-quick-reply-bubble" */).then(c => wrapFunctional(c.default || c))
export const AutoReplyRefCode = () => import('../../components/AutoReply/RefCode.vue' /* webpackChunkName: "components/auto-reply-ref-code" */).then(c => wrapFunctional(c.default || c))
export const AutoReplyTableListView = () => import('../../components/AutoReply/TableListView.vue' /* webpackChunkName: "components/auto-reply-table-list-view" */).then(c => wrapFunctional(c.default || c))
export const AutoReplyTemplateModal = () => import('../../components/AutoReply/TemplateModal.vue' /* webpackChunkName: "components/auto-reply-template-modal" */).then(c => wrapFunctional(c.default || c))
export const AutoReplyVideoBubble = () => import('../../components/AutoReply/VideoBubble.vue' /* webpackChunkName: "components/auto-reply-video-bubble" */).then(c => wrapFunctional(c.default || c))
export const AutomationAssignDropdown = () => import('../../components/Automation/AssignDropdown.vue' /* webpackChunkName: "components/automation-assign-dropdown" */).then(c => wrapFunctional(c.default || c))
export const AutomationAssignDropdownItem = () => import('../../components/Automation/AssignDropdownItem.vue' /* webpackChunkName: "components/automation-assign-dropdown-item" */).then(c => wrapFunctional(c.default || c))
export const AutomationChatStatusSettingTableListView = () => import('../../components/Automation/ChatStatusSettingTableListView.vue' /* webpackChunkName: "components/automation-chat-status-setting-table-list-view" */).then(c => wrapFunctional(c.default || c))
export const AutomationPatternDropdownItem = () => import('../../components/Automation/PatternDropdownItem.vue' /* webpackChunkName: "components/automation-pattern-dropdown-item" */).then(c => wrapFunctional(c.default || c))
export const AutomationPatternTag = () => import('../../components/Automation/PatternTag.vue' /* webpackChunkName: "components/automation-pattern-tag" */).then(c => wrapFunctional(c.default || c))
export const AutomationStateTag = () => import('../../components/Automation/StateTag.vue' /* webpackChunkName: "components/automation-state-tag" */).then(c => wrapFunctional(c.default || c))
export const AutomationTeamSettingTableListView = () => import('../../components/Automation/TeamSettingTableListView.vue' /* webpackChunkName: "components/automation-team-setting-table-list-view" */).then(c => wrapFunctional(c.default || c))
export const BroadcastDetailForm = () => import('../../components/Broadcast/DetailForm.vue' /* webpackChunkName: "components/broadcast-detail-form" */).then(c => wrapFunctional(c.default || c))
export const BroadcastDialog = () => import('../../components/Broadcast/Dialog.vue' /* webpackChunkName: "components/broadcast-dialog" */).then(c => wrapFunctional(c.default || c))
export const BroadcastErrorAccess = () => import('../../components/Broadcast/ErrorAccess.vue' /* webpackChunkName: "components/broadcast-error-access" */).then(c => wrapFunctional(c.default || c))
export const BroadcastInputPattern = () => import('../../components/Broadcast/InputPattern.vue' /* webpackChunkName: "components/broadcast-input-pattern" */).then(c => wrapFunctional(c.default || c))
export const BroadcastPageHeader = () => import('../../components/Broadcast/PageHeader.vue' /* webpackChunkName: "components/broadcast-page-header" */).then(c => wrapFunctional(c.default || c))
export const BroadcastSummaryAudience = () => import('../../components/Broadcast/SummaryAudience.vue' /* webpackChunkName: "components/broadcast-summary-audience" */).then(c => wrapFunctional(c.default || c))
export const BroadcastTable = () => import('../../components/Broadcast/Table.vue' /* webpackChunkName: "components/broadcast-table" */).then(c => wrapFunctional(c.default || c))
export const BroadcastTableRow = () => import('../../components/Broadcast/TableRow.vue' /* webpackChunkName: "components/broadcast-table-row" */).then(c => wrapFunctional(c.default || c))
export const BroadcastTableTitle = () => import('../../components/Broadcast/TableTitle.vue' /* webpackChunkName: "components/broadcast-table-title" */).then(c => wrapFunctional(c.default || c))
export const BroadcastTriggersOut = () => import('../../components/Broadcast/TriggersOut.vue' /* webpackChunkName: "components/broadcast-triggers-out" */).then(c => wrapFunctional(c.default || c))
export const CaseActivity = () => import('../../components/Case/Activity.vue' /* webpackChunkName: "components/case-activity" */).then(c => wrapFunctional(c.default || c))
export const CaseActivityContent = () => import('../../components/Case/ActivityContent.vue' /* webpackChunkName: "components/case-activity-content" */).then(c => wrapFunctional(c.default || c))
export const CaseActivityItem = () => import('../../components/Case/ActivityItem.vue' /* webpackChunkName: "components/case-activity-item" */).then(c => wrapFunctional(c.default || c))
export const CaseDialog = () => import('../../components/Case/Dialog.vue' /* webpackChunkName: "components/case-dialog" */).then(c => wrapFunctional(c.default || c))
export const CaseEndDialog = () => import('../../components/Case/EndDialog.vue' /* webpackChunkName: "components/case-end-dialog" */).then(c => wrapFunctional(c.default || c))
export const CaseList = () => import('../../components/Case/List.vue' /* webpackChunkName: "components/case-list" */).then(c => wrapFunctional(c.default || c))
export const CaseStatusLabel = () => import('../../components/Case/StatusLabel.vue' /* webpackChunkName: "components/case-status-label" */).then(c => wrapFunctional(c.default || c))
export const CaseTable = () => import('../../components/Case/Table.vue' /* webpackChunkName: "components/case-table" */).then(c => wrapFunctional(c.default || c))
export const CaseTitleEditable = () => import('../../components/Case/TitleEditable.vue' /* webpackChunkName: "components/case-title-editable" */).then(c => wrapFunctional(c.default || c))
export const CommentReplyEditorUserInput = () => import('../../components/CommentReply/EditorUserInput.vue' /* webpackChunkName: "components/comment-reply-editor-user-input" */).then(c => wrapFunctional(c.default || c))
export const CommentReplyTableListView = () => import('../../components/CommentReply/TableListView.vue' /* webpackChunkName: "components/comment-reply-table-list-view" */).then(c => wrapFunctional(c.default || c))
export const ContactFilterTable = () => import('../../components/Contact/FilterTable.vue' /* webpackChunkName: "components/contact-filter-table" */).then(c => wrapFunctional(c.default || c))
export const ContactTable = () => import('../../components/Contact/Table.vue' /* webpackChunkName: "components/contact-table" */).then(c => wrapFunctional(c.default || c))
export const ContactProfileAddressInput = () => import('../../components/ContactProfile/AddressInput.vue' /* webpackChunkName: "components/contact-profile-address-input" */).then(c => wrapFunctional(c.default || c))
export const ContactProfileAutocompleteInput = () => import('../../components/ContactProfile/AutocompleteInput.vue' /* webpackChunkName: "components/contact-profile-autocomplete-input" */).then(c => wrapFunctional(c.default || c))
export const ContactProfileDatePickerInput = () => import('../../components/ContactProfile/DatePickerInput.vue' /* webpackChunkName: "components/contact-profile-date-picker-input" */).then(c => wrapFunctional(c.default || c))
export const ContactProfileDefaultValue = () => import('../../components/ContactProfile/DefaultValue.vue' /* webpackChunkName: "components/contact-profile-default-value" */).then(c => wrapFunctional(c.default || c))
export const ContactProfileInputManagement = () => import('../../components/ContactProfile/InputManagement.vue' /* webpackChunkName: "components/contact-profile-input-management" */).then(c => wrapFunctional(c.default || c))
export const ContactProfilePanel = () => import('../../components/ContactProfile/Panel.vue' /* webpackChunkName: "components/contact-profile-panel" */).then(c => wrapFunctional(c.default || c))
export const ContactProfileTextInput = () => import('../../components/ContactProfile/TextInput.vue' /* webpackChunkName: "components/contact-profile-text-input" */).then(c => wrapFunctional(c.default || c))
export const ContactProfileUserNameInput = () => import('../../components/ContactProfile/UserNameInput.vue' /* webpackChunkName: "components/contact-profile-user-name-input" */).then(c => wrapFunctional(c.default || c))
export const ConversationStarterEditorTriggersOut = () => import('../../components/ConversationStarter/EditorTriggersOut.vue' /* webpackChunkName: "components/conversation-starter-editor-triggers-out" */).then(c => wrapFunctional(c.default || c))
export const ConversationStarterGridLayout = () => import('../../components/ConversationStarter/GridLayout.vue' /* webpackChunkName: "components/conversation-starter-grid-layout" */).then(c => wrapFunctional(c.default || c))
export const ConversationStarterTableListView = () => import('../../components/ConversationStarter/TableListView.vue' /* webpackChunkName: "components/conversation-starter-table-list-view" */).then(c => wrapFunctional(c.default || c))
export const CreateBusinessConnectFacebookPageWithOho = () => import('../../components/CreateBusiness/ConnectFacebookPageWithOho.vue' /* webpackChunkName: "components/create-business-connect-facebook-page-with-oho" */).then(c => wrapFunctional(c.default || c))
export const CreateBusinessInviteMember = () => import('../../components/CreateBusiness/InviteMember.vue' /* webpackChunkName: "components/create-business-invite-member" */).then(c => wrapFunctional(c.default || c))
export const CreateBusinessSelectPageAndCreateBusiness = () => import('../../components/CreateBusiness/SelectPageAndCreateBusiness.vue' /* webpackChunkName: "components/create-business-select-page-and-create-business" */).then(c => wrapFunctional(c.default || c))
export const CreateBusinessSetAutoReply = () => import('../../components/CreateBusiness/SetAutoReply.vue' /* webpackChunkName: "components/create-business-set-auto-reply" */).then(c => wrapFunctional(c.default || c))
export const DashboardAdminPerformance = () => import('../../components/Dashboard/AdminPerformance.vue' /* webpackChunkName: "components/dashboard-admin-performance" */).then(c => wrapFunctional(c.default || c))
export const DashboardChatPerformance = () => import('../../components/Dashboard/ChatPerformance.vue' /* webpackChunkName: "components/dashboard-chat-performance" */).then(c => wrapFunctional(c.default || c))
export const DashboardCustomerRatio = () => import('../../components/Dashboard/CustomerRatio.vue' /* webpackChunkName: "components/dashboard-customer-ratio" */).then(c => wrapFunctional(c.default || c))
export const DashboardCustomerTag = () => import('../../components/Dashboard/CustomerTag.vue' /* webpackChunkName: "components/dashboard-customer-tag" */).then(c => wrapFunctional(c.default || c))
export const DashboardDonutChart = () => import('../../components/Dashboard/DonutChart.vue' /* webpackChunkName: "components/dashboard-donut-chart" */).then(c => wrapFunctional(c.default || c))
export const DashboardFrequencyPeriod = () => import('../../components/Dashboard/FrequencyPeriod.vue' /* webpackChunkName: "components/dashboard-frequency-period" */).then(c => wrapFunctional(c.default || c))
export const DashboardHeatMap = () => import('../../components/Dashboard/HeatMap.vue' /* webpackChunkName: "components/dashboard-heat-map" */).then(c => wrapFunctional(c.default || c))
export const DashboardLineChart = () => import('../../components/Dashboard/LineChart.vue' /* webpackChunkName: "components/dashboard-line-chart" */).then(c => wrapFunctional(c.default || c))
export const DashboardLineChartMultipleAxis = () => import('../../components/Dashboard/LineChartMultipleAxis.vue' /* webpackChunkName: "components/dashboard-line-chart-multiple-axis" */).then(c => wrapFunctional(c.default || c))
export const DashboardMostArp = () => import('../../components/Dashboard/MostArp.vue' /* webpackChunkName: "components/dashboard-most-arp" */).then(c => wrapFunctional(c.default || c))
export const DashboardMostTalked = () => import('../../components/Dashboard/MostTalked.vue' /* webpackChunkName: "components/dashboard-most-talked" */).then(c => wrapFunctional(c.default || c))
export const DashboardPersonalOverall = () => import('../../components/Dashboard/PersonalOverall.vue' /* webpackChunkName: "components/dashboard-personal-overall" */).then(c => wrapFunctional(c.default || c))
export const DashboardPolarity = () => import('../../components/Dashboard/Polarity.vue' /* webpackChunkName: "components/dashboard-polarity" */).then(c => wrapFunctional(c.default || c))
export const DashboardProcessBarChart = () => import('../../components/Dashboard/ProcessBarChart.vue' /* webpackChunkName: "components/dashboard-process-bar-chart" */).then(c => wrapFunctional(c.default || c))
export const DashboardServicePerformance = () => import('../../components/Dashboard/ServicePerformance.vue' /* webpackChunkName: "components/dashboard-service-performance" */).then(c => wrapFunctional(c.default || c))
export const Error = () => import('../../components/Error/404.vue' /* webpackChunkName: "components/error" */).then(c => wrapFunctional(c.default || c))
export const FilterManagementCheckboxList = () => import('../../components/FilterManagement/CheckboxList.vue' /* webpackChunkName: "components/filter-management-checkbox-list" */).then(c => wrapFunctional(c.default || c))
export const FilterManagementCheckboxListSearch = () => import('../../components/FilterManagement/CheckboxListSearch.vue' /* webpackChunkName: "components/filter-management-checkbox-list-search" */).then(c => wrapFunctional(c.default || c))
export const FilterManagementDisplayList = () => import('../../components/FilterManagement/DisplayList.vue' /* webpackChunkName: "components/filter-management-display-list" */).then(c => wrapFunctional(c.default || c))
export const FilterManagementListControl = () => import('../../components/FilterManagement/ListControl.vue' /* webpackChunkName: "components/filter-management-list-control" */).then(c => wrapFunctional(c.default || c))
export const FilterManagementModal = () => import('../../components/FilterManagement/Modal.vue' /* webpackChunkName: "components/filter-management-modal" */).then(c => wrapFunctional(c.default || c))
export const FilterManagementPreset = () => import('../../components/FilterManagement/Preset.vue' /* webpackChunkName: "components/filter-management-preset" */).then(c => wrapFunctional(c.default || c))
export const FilterManagementRadioList = () => import('../../components/FilterManagement/RadioList.vue' /* webpackChunkName: "components/filter-management-radio-list" */).then(c => wrapFunctional(c.default || c))
export const FilterManagementRadioListCustomDate = () => import('../../components/FilterManagement/RadioListCustomDate.vue' /* webpackChunkName: "components/filter-management-radio-list-custom-date" */).then(c => wrapFunctional(c.default || c))
export const FilterManagementRadioListSearch = () => import('../../components/FilterManagement/RadioListSearch.vue' /* webpackChunkName: "components/filter-management-radio-list-search" */).then(c => wrapFunctional(c.default || c))
export const FilterManagementTag = () => import('../../components/FilterManagement/Tag.vue' /* webpackChunkName: "components/filter-management-tag" */).then(c => wrapFunctional(c.default || c))
export const FormSelectMember = () => import('../../components/Form/SelectMember.vue' /* webpackChunkName: "components/form-select-member" */).then(c => wrapFunctional(c.default || c))
export const FormSelectTeam = () => import('../../components/Form/SelectTeam.vue' /* webpackChunkName: "components/form-select-team" */).then(c => wrapFunctional(c.default || c))
export const LabelItem = () => import('../../components/Label/Item.vue' /* webpackChunkName: "components/label-item" */).then(c => wrapFunctional(c.default || c))
export const LabelRoomList = () => import('../../components/Label/RoomList.vue' /* webpackChunkName: "components/label-room-list" */).then(c => wrapFunctional(c.default || c))
export const MediaLibraryTable = () => import('../../components/MediaLibrary/Table.vue' /* webpackChunkName: "components/media-library-table" */).then(c => wrapFunctional(c.default || c))
export const MemberProfile = () => import('../../components/Member/Profile.vue' /* webpackChunkName: "components/member-profile" */).then(c => wrapFunctional(c.default || c))
export const MemberProfileCompact = () => import('../../components/Member/ProfileCompact.vue' /* webpackChunkName: "components/member-profile-compact" */).then(c => wrapFunctional(c.default || c))
export const MemberProfileEditForm = () => import('../../components/Member/ProfileEditForm.vue' /* webpackChunkName: "components/member-profile-edit-form" */).then(c => wrapFunctional(c.default || c))
export const MemberProfileImage = () => import('../../components/Member/ProfileImage.vue' /* webpackChunkName: "components/member-profile-image" */).then(c => wrapFunctional(c.default || c))
export const MemberProfileLabel = () => import('../../components/Member/ProfileLabel.vue' /* webpackChunkName: "components/member-profile-label" */).then(c => wrapFunctional(c.default || c))
export const MemberProfileList = () => import('../../components/Member/ProfileList.vue' /* webpackChunkName: "components/member-profile-list" */).then(c => wrapFunctional(c.default || c))
export const MemberProfileModal = () => import('../../components/Member/ProfileModal.vue' /* webpackChunkName: "components/member-profile-modal" */).then(c => wrapFunctional(c.default || c))
export const NameCardMember = () => import('../../components/NameCard/Member.vue' /* webpackChunkName: "components/name-card-member" */).then(c => wrapFunctional(c.default || c))
export const NameCardTeam = () => import('../../components/NameCard/Team.vue' /* webpackChunkName: "components/name-card-team" */).then(c => wrapFunctional(c.default || c))
export const OnlineStatusDropdown = () => import('../../components/OnlineStatus/Dropdown.vue' /* webpackChunkName: "components/online-status-dropdown" */).then(c => wrapFunctional(c.default || c))
export const PaymentDialog = () => import('../../components/Payment/Dialog.vue' /* webpackChunkName: "components/payment-dialog" */).then(c => wrapFunctional(c.default || c))
export const PaymentDownloadReceiptDialog = () => import('../../components/Payment/DownloadReceiptDialog.vue' /* webpackChunkName: "components/payment-download-receipt-dialog" */).then(c => wrapFunctional(c.default || c))
export const PaymentPackage = () => import('../../components/Payment/package.vue' /* webpackChunkName: "components/payment-package" */).then(c => wrapFunctional(c.default || c))
export const PopoverMemberList = () => import('../../components/Popover/MemberList.vue' /* webpackChunkName: "components/popover-member-list" */).then(c => wrapFunctional(c.default || c))
export const PopoverTeamList = () => import('../../components/Popover/TeamList.vue' /* webpackChunkName: "components/popover-team-list" */).then(c => wrapFunctional(c.default || c))
export const PreviewAutoDetect = () => import('../../components/Preview/AutoDetect.vue' /* webpackChunkName: "components/preview-auto-detect" */).then(c => wrapFunctional(c.default || c))
export const PreviewMediaItem = () => import('../../components/Preview/MediaItem.vue' /* webpackChunkName: "components/preview-media-item" */).then(c => wrapFunctional(c.default || c))
export const PreviewMessagesDrawer = () => import('../../components/Preview/MessagesDrawer.vue' /* webpackChunkName: "components/preview-messages-drawer" */).then(c => wrapFunctional(c.default || c))
export const SaleOwnerAssignModal = () => import('../../components/SaleOwner/AssignModal.vue' /* webpackChunkName: "components/sale-owner-assign-modal" */).then(c => wrapFunctional(c.default || c))
export const SaleOwnerAssignPopover = () => import('../../components/SaleOwner/AssignPopover.vue' /* webpackChunkName: "components/sale-owner-assign-popover" */).then(c => wrapFunctional(c.default || c))
export const SaleOwnerInfo = () => import('../../components/SaleOwner/Info.vue' /* webpackChunkName: "components/sale-owner-info" */).then(c => wrapFunctional(c.default || c))
export const SaleOwnerPanel = () => import('../../components/SaleOwner/Panel.vue' /* webpackChunkName: "components/sale-owner-panel" */).then(c => wrapFunctional(c.default || c))
export const SavedReplyCopyAutoReplyState = () => import('../../components/SavedReply/CopyAutoReplyState.vue' /* webpackChunkName: "components/saved-reply-copy-auto-reply-state" */).then(c => wrapFunctional(c.default || c))
export const SavedReplyCreateState = () => import('../../components/SavedReply/CreateState.vue' /* webpackChunkName: "components/saved-reply-create-state" */).then(c => wrapFunctional(c.default || c))
export const SavedReplyDetailForm = () => import('../../components/SavedReply/DetailForm.vue' /* webpackChunkName: "components/saved-reply-detail-form" */).then(c => wrapFunctional(c.default || c))
export const SavedReplyEditBeforeSendState = () => import('../../components/SavedReply/EditBeforeSendState.vue' /* webpackChunkName: "components/saved-reply-edit-before-send-state" */).then(c => wrapFunctional(c.default || c))
export const SavedReplyEditState = () => import('../../components/SavedReply/EditState.vue' /* webpackChunkName: "components/saved-reply-edit-state" */).then(c => wrapFunctional(c.default || c))
export const SavedReplyInputPattern = () => import('../../components/SavedReply/InputPattern.vue' /* webpackChunkName: "components/saved-reply-input-pattern" */).then(c => wrapFunctional(c.default || c))
export const SavedReplyItem = () => import('../../components/SavedReply/Item.vue' /* webpackChunkName: "components/saved-reply-item" */).then(c => wrapFunctional(c.default || c))
export const SavedReplyModal = () => import('../../components/SavedReply/Modal.vue' /* webpackChunkName: "components/saved-reply-modal" */).then(c => wrapFunctional(c.default || c))
export const SavedReplySelectionState = () => import('../../components/SavedReply/SelectionState.vue' /* webpackChunkName: "components/saved-reply-selection-state" */).then(c => wrapFunctional(c.default || c))
export const SavedReplyTable = () => import('../../components/SavedReply/Table.vue' /* webpackChunkName: "components/saved-reply-table" */).then(c => wrapFunctional(c.default || c))
export const SavedReplyTableRow = () => import('../../components/SavedReply/TableRow.vue' /* webpackChunkName: "components/saved-reply-table-row" */).then(c => wrapFunctional(c.default || c))
export const SavedReplyTriggersOut = () => import('../../components/SavedReply/TriggersOut.vue' /* webpackChunkName: "components/saved-reply-triggers-out" */).then(c => wrapFunctional(c.default || c))
export const SettingDisconnectFacebookDialog = () => import('../../components/Setting/DisconnectFacebookDialog.vue' /* webpackChunkName: "components/setting-disconnect-facebook-dialog" */).then(c => wrapFunctional(c.default || c))
export const SettingDisconnectInstagramDialog = () => import('../../components/Setting/DisconnectInstagramDialog.vue' /* webpackChunkName: "components/setting-disconnect-instagram-dialog" */).then(c => wrapFunctional(c.default || c))
export const SettingDisconnectLineDialog = () => import('../../components/Setting/DisconnectLineDialog.vue' /* webpackChunkName: "components/setting-disconnect-line-dialog" */).then(c => wrapFunctional(c.default || c))
export const SettingDisconnectTiktokDialog = () => import('../../components/Setting/DisconnectTiktokDialog.vue' /* webpackChunkName: "components/setting-disconnect-tiktok-dialog" */).then(c => wrapFunctional(c.default || c))
export const SettingFacebookIntegrationDialog = () => import('../../components/Setting/FacebookIntegrationDialog.vue' /* webpackChunkName: "components/setting-facebook-integration-dialog" */).then(c => wrapFunctional(c.default || c))
export const SettingInstagramIntegrationDialog = () => import('../../components/Setting/InstagramIntegrationDialog.vue' /* webpackChunkName: "components/setting-instagram-integration-dialog" */).then(c => wrapFunctional(c.default || c))
export const SettingInviteMemberForm = () => import('../../components/Setting/InviteMemberForm.vue' /* webpackChunkName: "components/setting-invite-member-form" */).then(c => wrapFunctional(c.default || c))
export const SettingInviteTableListView = () => import('../../components/Setting/InviteTableListView.vue' /* webpackChunkName: "components/setting-invite-table-list-view" */).then(c => wrapFunctional(c.default || c))
export const SettingLineIntegrationDialog = () => import('../../components/Setting/LineIntegrationDialog.vue' /* webpackChunkName: "components/setting-line-integration-dialog" */).then(c => wrapFunctional(c.default || c))
export const SettingMemberRoleUsage = () => import('../../components/Setting/MemberRoleUsage.vue' /* webpackChunkName: "components/setting-member-role-usage" */).then(c => wrapFunctional(c.default || c))
export const SettingMemberTableListView = () => import('../../components/Setting/MemberTableListView.vue' /* webpackChunkName: "components/setting-member-table-list-view" */).then(c => wrapFunctional(c.default || c))
export const SettingTiktokIntegrationDialog = () => import('../../components/Setting/TiktokIntegrationDialog.vue' /* webpackChunkName: "components/setting-tiktok-integration-dialog" */).then(c => wrapFunctional(c.default || c))
export const SettingUserEditModal = () => import('../../components/Setting/UserEditModal.vue' /* webpackChunkName: "components/setting-user-edit-modal" */).then(c => wrapFunctional(c.default || c))
export const SettingUserSettingsDialog = () => import('../../components/Setting/UserSettingsDialog.vue' /* webpackChunkName: "components/setting-user-settings-dialog" */).then(c => wrapFunctional(c.default || c))
export const SmartchatAssignProfile = () => import('../../components/Smartchat/AssignProfile.vue' /* webpackChunkName: "components/smartchat-assign-profile" */).then(c => wrapFunctional(c.default || c))
export const SmartchatAudioPlayer = () => import('../../components/Smartchat/AudioPlayer.vue' /* webpackChunkName: "components/smartchat-audio-player" */).then(c => wrapFunctional(c.default || c))
export const SmartchatConversation = () => import('../../components/Smartchat/Conversation.vue' /* webpackChunkName: "components/smartchat-conversation" */).then(c => wrapFunctional(c.default || c))
export const SmartchatFilterChatList = () => import('../../components/Smartchat/FilterChatList.vue' /* webpackChunkName: "components/smartchat-filter-chat-list" */).then(c => wrapFunctional(c.default || c))
export const SmartchatFilterTagSelected = () => import('../../components/Smartchat/FilterTagSelected.vue' /* webpackChunkName: "components/smartchat-filter-tag-selected" */).then(c => wrapFunctional(c.default || c))
export const SmartchatModalBulkMessage = () => import('../../components/Smartchat/ModalBulkMessage.vue' /* webpackChunkName: "components/smartchat-modal-bulk-message" */).then(c => wrapFunctional(c.default || c))
export const SmartchatNavBar = () => import('../../components/Smartchat/NavBar.vue' /* webpackChunkName: "components/smartchat-nav-bar" */).then(c => wrapFunctional(c.default || c))
export const SmartchatRoom = () => import('../../components/Smartchat/Room.vue' /* webpackChunkName: "components/smartchat-room" */).then(c => wrapFunctional(c.default || c))
export const SmartchatRoomLabel = () => import('../../components/Smartchat/RoomLabel.vue' /* webpackChunkName: "components/smartchat-room-label" */).then(c => wrapFunctional(c.default || c))
export const SmartchatRoomList = () => import('../../components/Smartchat/RoomList.vue' /* webpackChunkName: "components/smartchat-room-list" */).then(c => wrapFunctional(c.default || c))
export const SmartchatRoomStatus = () => import('../../components/Smartchat/RoomStatus.vue' /* webpackChunkName: "components/smartchat-room-status" */).then(c => wrapFunctional(c.default || c))
export const SmartchatSendMessageDisabled = () => import('../../components/Smartchat/SendMessageDisabled.vue' /* webpackChunkName: "components/smartchat-send-message-disabled" */).then(c => wrapFunctional(c.default || c))
export const SmartchatStatusToggle = () => import('../../components/Smartchat/StatusToggle.vue' /* webpackChunkName: "components/smartchat-status-toggle" */).then(c => wrapFunctional(c.default || c))
export const TagBroadcast = () => import('../../components/Tag/Broadcast.vue' /* webpackChunkName: "components/tag-broadcast" */).then(c => wrapFunctional(c.default || c))
export const TagCase = () => import('../../components/Tag/Case.vue' /* webpackChunkName: "components/tag-case" */).then(c => wrapFunctional(c.default || c))
export const TagChat = () => import('../../components/Tag/Chat.vue' /* webpackChunkName: "components/tag-chat" */).then(c => wrapFunctional(c.default || c))
export const TagContact = () => import('../../components/Tag/Contact.vue' /* webpackChunkName: "components/tag-contact" */).then(c => wrapFunctional(c.default || c))
export const TagDefault = () => import('../../components/Tag/Default.vue' /* webpackChunkName: "components/tag-default" */).then(c => wrapFunctional(c.default || c))
export const TagItem = () => import('../../components/Tag/Item.vue' /* webpackChunkName: "components/tag-item" */).then(c => wrapFunctional(c.default || c))
export const TagManagement = () => import('../../components/TagManagement/Index.vue' /* webpackChunkName: "components/tag-management" */).then(c => wrapFunctional(c.default || c))
export const TagManagementListPopover = () => import('../../components/TagManagement/ListPopover.vue' /* webpackChunkName: "components/tag-management-list-popover" */).then(c => wrapFunctional(c.default || c))
export const TagManagementPopover = () => import('../../components/TagManagement/Popover.vue' /* webpackChunkName: "components/tag-management-popover" */).then(c => wrapFunctional(c.default || c))
export const TagManagementReferralList = () => import('../../components/TagManagement/ReferralList.vue' /* webpackChunkName: "components/tag-management-referral-list" */).then(c => wrapFunctional(c.default || c))
export const TagManagementRemoveDialog = () => import('../../components/TagManagement/RemoveDialog.vue' /* webpackChunkName: "components/tag-management-remove-dialog" */).then(c => wrapFunctional(c.default || c))
export const TeamField = () => import('../../components/Team/Field.vue' /* webpackChunkName: "components/team-field" */).then(c => wrapFunctional(c.default || c))
export const TeamForm = () => import('../../components/Team/Form.vue' /* webpackChunkName: "components/team-form" */).then(c => wrapFunctional(c.default || c))
export const TeamLogo = () => import('../../components/Team/Logo.vue' /* webpackChunkName: "components/team-logo" */).then(c => wrapFunctional(c.default || c))
export const TeamMemberSettingDialog = () => import('../../components/Team/MemberSettingDialog.vue' /* webpackChunkName: "components/team-member-setting-dialog" */).then(c => wrapFunctional(c.default || c))
export const TeamProfile = () => import('../../components/Team/Profile.vue' /* webpackChunkName: "components/team-profile" */).then(c => wrapFunctional(c.default || c))
export const TeamRemoveBtn = () => import('../../components/Team/RemoveBtn.vue' /* webpackChunkName: "components/team-remove-btn" */).then(c => wrapFunctional(c.default || c))
export const TeamSuggestion = () => import('../../components/Team/Suggestion.vue' /* webpackChunkName: "components/team-suggestion" */).then(c => wrapFunctional(c.default || c))
export const TeamSuggestionForMemberSetting = () => import('../../components/Team/SuggestionForMemberSetting.vue' /* webpackChunkName: "components/team-suggestion-for-member-setting" */).then(c => wrapFunctional(c.default || c))
export const TeamTable = () => import('../../components/Team/Table.vue' /* webpackChunkName: "components/team-table" */).then(c => wrapFunctional(c.default || c))
export const TeamTableRow = () => import('../../components/Team/TableRow.vue' /* webpackChunkName: "components/team-table-row" */).then(c => wrapFunctional(c.default || c))
export const TeamTag = () => import('../../components/Team/Tag.vue' /* webpackChunkName: "components/team-tag" */).then(c => wrapFunctional(c.default || c))
export const TeamTagList = () => import('../../components/Team/TagList.vue' /* webpackChunkName: "components/team-tag-list" */).then(c => wrapFunctional(c.default || c))
export const ContactProfileSelectDropdownItem = () => import('../../components/ContactProfile/Select/DropdownItem.vue' /* webpackChunkName: "components/contact-profile-select-dropdown-item" */).then(c => wrapFunctional(c.default || c))
export const ContactProfileSelectInput = () => import('../../components/ContactProfile/Select/Input.vue' /* webpackChunkName: "components/contact-profile-select-input" */).then(c => wrapFunctional(c.default || c))
export const PreviewMessagesAttachment = () => import('../../components/Preview/Messages/Attachment.vue' /* webpackChunkName: "components/preview-messages-attachment" */).then(c => wrapFunctional(c.default || c))
export const PreviewMessagesBlock = () => import('../../components/Preview/Messages/Block.vue' /* webpackChunkName: "components/preview-messages-block" */).then(c => wrapFunctional(c.default || c))
export const PreviewMessagesBubble = () => import('../../components/Preview/Messages/Bubble.vue' /* webpackChunkName: "components/preview-messages-bubble" */).then(c => wrapFunctional(c.default || c))
export const PreviewMessagesCarouselBubble = () => import('../../components/Preview/Messages/CarouselBubble.vue' /* webpackChunkName: "components/preview-messages-carousel-bubble" */).then(c => wrapFunctional(c.default || c))
export const PreviewMessagesCarouselImage = () => import('../../components/Preview/Messages/CarouselImage.vue' /* webpackChunkName: "components/preview-messages-carousel-image" */).then(c => wrapFunctional(c.default || c))
export const PreviewMessagesFullItem = () => import('../../components/Preview/Messages/FullItem.vue' /* webpackChunkName: "components/preview-messages-full-item" */).then(c => wrapFunctional(c.default || c))
export const PreviewMessagesImage = () => import('../../components/Preview/Messages/Image.vue' /* webpackChunkName: "components/preview-messages-image" */).then(c => wrapFunctional(c.default || c))
export const PreviewMessagesShortcutItem = () => import('../../components/Preview/Messages/ShortcutItem.vue' /* webpackChunkName: "components/preview-messages-shortcut-item" */).then(c => wrapFunctional(c.default || c))
export const PreviewReplyMessage = () => import('../../components/Preview/ReplyMessage/index.vue' /* webpackChunkName: "components/preview-reply-message" */).then(c => wrapFunctional(c.default || c))
export const SmartchatActionAcceptPopover = () => import('../../components/Smartchat/Action/AcceptPopover.vue' /* webpackChunkName: "components/smartchat-action-accept-popover" */).then(c => wrapFunctional(c.default || c))
export const SmartchatActionAssignPopover = () => import('../../components/Smartchat/Action/AssignPopover.vue' /* webpackChunkName: "components/smartchat-action-assign-popover" */).then(c => wrapFunctional(c.default || c))
export const SmartchatActionFilterPanel = () => import('../../components/Smartchat/Action/FilterPanel.vue' /* webpackChunkName: "components/smartchat-action-filter-panel" */).then(c => wrapFunctional(c.default || c))
export const SmartchatActionMemberList = () => import('../../components/Smartchat/Action/MemberList.vue' /* webpackChunkName: "components/smartchat-action-member-list" */).then(c => wrapFunctional(c.default || c))
export const SmartchatActionTeamList = () => import('../../components/Smartchat/Action/TeamList.vue' /* webpackChunkName: "components/smartchat-action-team-list" */).then(c => wrapFunctional(c.default || c))
export const SmartchatBubbleAds = () => import('../../components/Smartchat/Bubble/Ads.vue' /* webpackChunkName: "components/smartchat-bubble-ads" */).then(c => wrapFunctional(c.default || c))
export const SmartchatBubbleAudio = () => import('../../components/Smartchat/Bubble/Audio.vue' /* webpackChunkName: "components/smartchat-bubble-audio" */).then(c => wrapFunctional(c.default || c))
export const SmartchatBubbleCarousel = () => import('../../components/Smartchat/Bubble/Carousel.vue' /* webpackChunkName: "components/smartchat-bubble-carousel" */).then(c => wrapFunctional(c.default || c))
export const SmartchatBubbleCaseEvent = () => import('../../components/Smartchat/Bubble/CaseEvent.vue' /* webpackChunkName: "components/smartchat-bubble-case-event" */).then(c => wrapFunctional(c.default || c))
export const SmartchatBubbleCensoredAudio = () => import('../../components/Smartchat/Bubble/CensoredAudio.vue' /* webpackChunkName: "components/smartchat-bubble-censored-audio" */).then(c => wrapFunctional(c.default || c))
export const SmartchatBubbleCensoredPlaceholder = () => import('../../components/Smartchat/Bubble/CensoredPlaceholder.vue' /* webpackChunkName: "components/smartchat-bubble-censored-placeholder" */).then(c => wrapFunctional(c.default || c))
export const SmartchatBubbleCensoredPlaceholderShareBubble = () => import('../../components/Smartchat/Bubble/CensoredPlaceholderShareBubble.vue' /* webpackChunkName: "components/smartchat-bubble-censored-placeholder-share-bubble" */).then(c => wrapFunctional(c.default || c))
export const SmartchatBubbleCensoredText = () => import('../../components/Smartchat/Bubble/CensoredText.vue' /* webpackChunkName: "components/smartchat-bubble-censored-text" */).then(c => wrapFunctional(c.default || c))
export const SmartchatBubbleEventTitle = () => import('../../components/Smartchat/Bubble/EventTitle.vue' /* webpackChunkName: "components/smartchat-bubble-event-title" */).then(c => wrapFunctional(c.default || c))
export const SmartchatBubbleFile = () => import('../../components/Smartchat/Bubble/File.vue' /* webpackChunkName: "components/smartchat-bubble-file" */).then(c => wrapFunctional(c.default || c))
export const SmartchatBubbleImage = () => import('../../components/Smartchat/Bubble/Image.vue' /* webpackChunkName: "components/smartchat-bubble-image" */).then(c => wrapFunctional(c.default || c))
export const SmartchatBubbleLineCustomMessage = () => import('../../components/Smartchat/Bubble/LineCustomMessage.vue' /* webpackChunkName: "components/smartchat-bubble-line-custom-message" */).then(c => wrapFunctional(c.default || c))
export const SmartchatBubbleLineFlexMessage = () => import('../../components/Smartchat/Bubble/LineFlexMessage.vue' /* webpackChunkName: "components/smartchat-bubble-line-flex-message" */).then(c => wrapFunctional(c.default || c))
export const SmartchatBubblePostback = () => import('../../components/Smartchat/Bubble/Postback.vue' /* webpackChunkName: "components/smartchat-bubble-postback" */).then(c => wrapFunctional(c.default || c))
export const SmartchatBubbleQuickReply = () => import('../../components/Smartchat/Bubble/QuickReply.vue' /* webpackChunkName: "components/smartchat-bubble-quick-reply" */).then(c => wrapFunctional(c.default || c))
export const SmartchatBubbleReplyMessage = () => import('../../components/Smartchat/Bubble/ReplyMessage.vue' /* webpackChunkName: "components/smartchat-bubble-reply-message" */).then(c => wrapFunctional(c.default || c))
export const SmartchatBubbleShare = () => import('../../components/Smartchat/Bubble/Share.vue' /* webpackChunkName: "components/smartchat-bubble-share" */).then(c => wrapFunctional(c.default || c))
export const SmartchatBubbleSticker = () => import('../../components/Smartchat/Bubble/Sticker.vue' /* webpackChunkName: "components/smartchat-bubble-sticker" */).then(c => wrapFunctional(c.default || c))
export const SmartchatBubbleStoryMention = () => import('../../components/Smartchat/Bubble/StoryMention.vue' /* webpackChunkName: "components/smartchat-bubble-story-mention" */).then(c => wrapFunctional(c.default || c))
export const SmartchatBubbleStoryReply = () => import('../../components/Smartchat/Bubble/StoryReply.vue' /* webpackChunkName: "components/smartchat-bubble-story-reply" */).then(c => wrapFunctional(c.default || c))
export const SmartchatBubbleText = () => import('../../components/Smartchat/Bubble/Text.vue' /* webpackChunkName: "components/smartchat-bubble-text" */).then(c => wrapFunctional(c.default || c))
export const SmartchatBubbleVideo = () => import('../../components/Smartchat/Bubble/Video.vue' /* webpackChunkName: "components/smartchat-bubble-video" */).then(c => wrapFunctional(c.default || c))
export const PreviewReplyMessageBubbleText = () => import('../../components/Preview/ReplyMessage/Bubble/Text.vue' /* webpackChunkName: "components/preview-reply-message-bubble-text" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
